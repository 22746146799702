<template>
  <div class="center-wrapper">
    <div class="main-wrapper">
      <h1>{{ title }}</h1>
      <slot></slot>
      <router-link class="no-style" to="/">
        <div class="about-author">
          <div class="about-title">About the author</div>
          <div class="author">
            <img
              class="author-picture"
              src="@/assets/pp.png"
              alt="author-picture"
            />
            <div class="description">
              Thomas, <br />
              Computer Science student
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script charset="utf-8">
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss" >
a {
  color: #4271d6;
}
.note {
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 5px;
}
.about-title {
  margin: 10px;
  margin-left: 0px;
  font-size: 1.4em;
  font-weight: bold;
}
.author {
  display: flex;
  align-items: center;

  img {
    width: 100px;
  }
  .description {
    margin-left: 30px;
    text-align: justify;
  }
}
ul {
  margin-bottom: 0px;
  margin-left: 0px;
}
</style>
