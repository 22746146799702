<template>
  <articleBase title="Changing your barani twisting side">
    <p>
      Disclaimer : I am not a professionnal whatsoever, but just someone having
      fun and experimenting with flips and twists.
    </p>

    <p>
      You may have learned your barani in a certain way which resulted in its
      twisting direction not being the one you wanted. In my case, I was able to
      do simple and double twists with backwards rotations before learning the
      barani. I was twisting to the left backwards and somehow learned the
      barani by twisting to the right. This can cause problems if you want to
      combine your barani with backwards twists (barani in full out for
      example). However please note that it is possible to combine flips with
      different twisting directions in the same jump : if you only initiate the
      flip when taking off, and wait before initating the twist. Some people can
      do backflip-twist-backflip (so a triple flip) by managing to initiate and
      stop the twist during the jump, so why not changing twist directions ?
    </p>
    <p>
      After some research on the web and some experimenting with different
      exercises, I found a solution that worked pretty well for me. It seems to
      be a known problem among gymnasts, which is a good thing when it comes to
      trying to fix it. The issue was that I had the feeling of twisting to the
      left side while I was twisting to the right; because when your head is
      upside down, what was originally on your left is now on your right. To
      prevent this from happening, you first need to get used to the new
      twisting side, and for that, you can delay the twist until you are not
      upside down anymore : do 3/4 of a front flip and once your body is
      horizontally aligned to the ground and you can see what's in front of you,
      start twisting. Do some repetitions using this technique and once you feel
      confortable with it, start twisting a bit earlier, and earlier and earlier
      until you reached the timing you want.
    </p>
  </articleBase>
</template>

<script charset="utf-8">
import articleBase from "@/components/articleBase.vue";
export default {
  components: {
    articleBase,
  },
};
</script>

